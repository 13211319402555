<template>
  <div class="investors-table">
    <!-- Toolbar -->
    <div class="toolbar">
      <div class="left">
        <div class="toolbar-option">
          <v-select label="Account Setup" v-model="accountSetupFilter" :items="accountSetupDropdownOptions"
            class="form-control">
          </v-select>
        </div>
        <div class="toolbar-option">
          <v-select label="ID Verified" v-model="idVerifiedFilter" :items="idVerifiedDropdownOptions"
            class="form-control">
          </v-select>
        </div>
        <div class="toolbar-option">
          <v-select label="Approved" v-model="approvedFilter" :items="approvedDropdownOptions" class="form-control">
          </v-select>
        </div>
      </div>
      <div class="right">
        <v-text-field v-model="searchQuery" label="Search" type="text"></v-text-field>
      </div>
    </div>
    <!-- / Toolbar -->

    <v-data-table :headers="headers" :items="investors" :options.sync="options" :server-items-length="totalInvestors"
      :loading="loading" class="elevation-1">
      <template v-slot:item.account_setup="{ item }">
        <v-icon class="success--text" v-if="item.account_setup">mdi-check</v-icon>
        <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
      </template>
      <template v-slot:item.id_verified="{ item }">
        <v-icon class="success--text" v-if="item.id_verified">mdi-check</v-icon>
        <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
      </template>
      <template v-slot:item.approved="{ item }">
        <v-icon class="success--text" v-if="item.approved">mdi-check</v-icon>
        <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small depressed @click.native.prevent="$router.push('/investors/' + item.id)">
          <v-icon small class="me-2">mdi-eye</v-icon>
          View</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import investors from "../../api/investors";
export default {
  data() {
    return {
      accountSetupDropdownOptions: [
        {
          value: null,
          text: "Show All",
        },
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],

      idVerifiedDropdownOptions: [
        {
          value: null,
          text: "Show All",
        },
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],

      approvedDropdownOptions: [
        {
          value: null,
          text: "Show All",
        },
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],

      searchQuery: "",
      accountSetupFilter: null,
      idVerifiedFilter: null,
      approvedFilter: null,

      totalInvestors: 0,
      investors: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        // { text: "Email", value: "email" },
        // { text: "Phone Number", value: "phone_number" },
        { text: "Account Setup", value: "account_setup" },
        { text: "ID Verified", value: "id_verified" },
        { text: "Approved", value: "approved" },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },

    accountSetupFilter() {
      this.fetch();
    },

    idVerifiedFilter() {
      this.fetch();
    },

    approvedFilter() {
      this.fetch();
    },

    searchQuery() {
      this.fetch();
    }
  },
  methods: {
    getFormParams() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      params.resultsPerPage = itemsPerPage;
      params.page = page;
      if (sortBy.length) {
        params.sortField = _.first(sortBy);
      }
      if (sortDesc.length) {
        let isDescending = _.first(sortDesc);
        params.sortOrder = isDescending ? "descend" : "ascend";
      }

      if (this.accountSetupFilter !== null) {
        params.account_setup = this.accountSetupFilter;
      }

      if (this.idVerifiedFilter !== null) {
        params.id_verified = this.idVerifiedFilter;
      }

      if (this.approvedFilter !== null) {
        params.approved = this.approvedFilter;
      }

      if (this.searchQuery && this.searchQuery.trim().length) {
        params.q = this.searchQuery;
      }

      return params;
    },

    fetch() {
      this.loading = true;
      investors.search(this.getFormParams()).then((r) => {
        this.investors = r.data.data;
        this.totalInvestors = r.data.total;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.investors-table {
  .toolbar {
    margin-bottom: 10px;
    display: flex;

    flex-direction: column;

    @media screen and (min-width: 960px) {
      flex-direction: row;

      .left {
        display: flex;
      }

      .toolbar-option {
        max-width: 300px;
        margin-right: 30px;
      }

      .right {
        .v-input {
          width: 300px;
        }
      }
    }

    .left {
      flex-grow: 1;
      // display: flex;
    }

    .right {
      flex-shrink: 1;
    }

    .toolbar-option {
      width: 100%;
    }
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>