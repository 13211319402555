var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"investors-table"},[_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"toolbar-option"},[_c('v-select',{staticClass:"form-control",attrs:{"label":"Account Setup","items":_vm.accountSetupDropdownOptions},model:{value:(_vm.accountSetupFilter),callback:function ($$v) {_vm.accountSetupFilter=$$v},expression:"accountSetupFilter"}})],1),_c('div',{staticClass:"toolbar-option"},[_c('v-select',{staticClass:"form-control",attrs:{"label":"ID Verified","items":_vm.idVerifiedDropdownOptions},model:{value:(_vm.idVerifiedFilter),callback:function ($$v) {_vm.idVerifiedFilter=$$v},expression:"idVerifiedFilter"}})],1),_c('div',{staticClass:"toolbar-option"},[_c('v-select',{staticClass:"form-control",attrs:{"label":"Approved","items":_vm.approvedDropdownOptions},model:{value:(_vm.approvedFilter),callback:function ($$v) {_vm.approvedFilter=$$v},expression:"approvedFilter"}})],1)]),_c('div',{staticClass:"right"},[_c('v-text-field',{attrs:{"label":"Search","type":"text"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.investors,"options":_vm.options,"server-items-length":_vm.totalInvestors,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.account_setup",fn:function(ref){
var item = ref.item;
return [(item.account_setup)?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"danger--text"},[_vm._v("mdi-window-close")])]}},{key:"item.id_verified",fn:function(ref){
var item = ref.item;
return [(item.id_verified)?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"danger--text"},[_vm._v("mdi-window-close")])]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [(item.approved)?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"danger--text"},[_vm._v("mdi-window-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","depressed":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.$router.push('/investors/' + item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" View")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }